import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../../components/Layout";
import {Helmet} from "react-helmet";

const SamplersPage = ({data, count}) => {
  const {edges: posts} = data.allMarkdownRemark;
  return (
    <Layout title="Samplers">
      <Helmet>
        <title>76666 - SAMPLERS</title>
        <meta
          name="description"
          content="76666 Samplers"
        />
      </Helmet>
      <div className="plain-grid">
        {posts.map(post => (
          <Link to={post.node.fields.slug} key={post.node.fields.slug}>
            <div
              className="plain-grid-img"
              aria-label={post.node.frontmatter.title}
            >
              <Img
                fluid={post.node.frontmatter.coverimage.childImageSharp.fluid}
                alt={`Cover of sampler "${post.node.frontmatter.title}"`}
              />
              <div className="release-title">{post.node.frontmatter.title}</div>
            </div>
          </Link>)
        )}
      </div>
    </Layout>

  );
};


export default () => (
  <StaticQuery
    query={graphql`
      query SamplersGridQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter:  {frontmatter: {category: {eq: "samplers"}}}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                coverimage {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <SamplersPage data={data} count={count}/>}
  />
)

